import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import classes from './EditPost.module.css';

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {
        title: '',
        description: '',
        image: null,
        content: '',
        private: false,
      },
      postSubmit: {
        submitted: false,
        success: false,
      },
    };
    this.modalToggler = this.modalToggler.bind(this);
    // form input handler
    this.handleImagePreview = this.handleImagePreview.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async componentDidMount(event) {
    try {
      const id = this.props.match.params.id;
      const config = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: true,
      };
      const postRes = await axios.get('/api/blogs/' + id + '/edit', config);
      if (postRes.data.post){
        this.setState({
          post: {...postRes.data.post},
        })
      } else {
        this.props.history.push('/blogs');
      }
    } catch(err) {
      console.log(err);
    }
  }

  modalToggler() {
    const id = this.props.match.params.id;
    this.setState((prevState) => ({
      postSubmit: {
        submitted: !prevState.postSubmit.submitted,
      },
    }));
    this.props.history.push('/blogs/' + id);
  }

  handleImagePreview(event) {
    const imageFile = event.target.files[0];
    if (imageFile) {
      this.setState((prevState, props) => ({
        post: {...prevState.post, image: imageFile}
      }));
    } else {
      this.setState((prevState, props) => ({
        post: {...prevState.post, image: null}
      }));
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((prevState, props) => ({
      post: {...prevState.post, [name]: value,}
    }));
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      const id = this.props.match.params.id;
      let formData = new FormData();
      formData.append('image', this.state.post.image);
      formData.append('title', this.state.post.title);
      formData.append('description', this.state.post.description);
      formData.append('content', this.state.post.content);
      formData.append('private', this.state.post.private);

      const config = {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: true,
      };
      axios.put('/api/blogs/' + id, formData, config);
      this.setState({
        postSubmit: {
          submitted: true,
          success: true,
        },
      });
    } catch(err) {
      console.log(err);
      this.setState({
        postSubmit: {
          submitted: true,
          success: false,
        },
      });
    }
  }
  
  render() {
    const imgSrc = (this.state.post.image) ? (this.state.post.image.link !== undefined) ? this.state.post.image.link : URL.createObjectURL(this.state.post.image) : null;
    if (!this.props.currentUser.authenticated) {
      return (
        <div className={classes.editPost + ' container content'}> </div>
      );
    }
    return (
      <div className={classes.editPost + ' container content'}>
        <div className="row mt-4">
          <div className="col-12 col-sm-8">
            <h1 className={classes.title}>Edit Post</h1>
          </div>
          <div className="col col-sm">
            <Button tag={Link} to="/blogs" color="primary" size="lg" className="mt-3 mt-sm-0">
              Return To Blog
            </Button>
          </div>
        </div>
        <hr />
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label className={classes.formLabel} for="title">
              Title
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Title"
              defaultValue={this.state.post.title}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="title">
              Description
            </Label>
            <Input
              type="text"
              name="description"
              id="description"
              placeholder="Enter Description"
              defaultValue={this.state.post.description}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="content">
              Post Content
            </Label>
            <Input
              type="textarea"
              name="content"
              id="content"
              placeholder="Enter Content"
              rows="20"
              defaultValue={this.state.post.content}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="image">
              Image
            </Label>
            <Input
              type="file"
              name="image"
              id="image"
              onChange={this.handleImagePreview}
            />
            <FormText color="red">Upload image for this post.</FormText>
            <img
              src={
                imgSrc
              }
              alt="img"
              style={{ width: '300px' }}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="private"
                id="private"
                checked={this.state.post.private}
                onChange={this.handleChange}
              />{' '}
              Private Post
            </Label>
          </FormGroup>
          <Button className="mt-3 mb-5">Submit</Button>
          <Button tag={Link} to='/blogs' color="danger" className="mt-3 mb-5" style={{float:'right'}}>Cancel</Button>
        </Form>
        <Modal
          isOpen={this.state.postSubmit.submitted}
          toggle={this.modalToggler}
        >
          <ModalHeader toggle={this.modalToggler}>Post Update Information</ModalHeader>
          <ModalBody>
            {this.state.postSubmit.success ? 'Successful!' : 'Failed!'}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps)(withRouter(EditPost));
