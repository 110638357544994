import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { initialFeedback } from './Form/forms';
import { User } from './User/user';
import { createForms } from 'react-redux-form';

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      currentUser: User,
      ...createForms({
        feedback: initialFeedback,
      }),
    }),
    applyMiddleware(ReduxThunk)
  );

  return store;
};
