import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProgressiveImage from "react-progressive-graceful-image";
import Spinner from '../../Spinner/Spinner';
import classes from './IntroComponent.module.css';
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap';

AOS.init({
  duration: 1200,
});

const Intro = (props) => {
  return (
    <div>
      <Card className={classes.card}>
        <div className={classes.cardimgs + ' row'}>
          <div className={classes.cardimgWrap + ' col-4'}>
            <ProgressiveImage src='./assets/me2.jpg' placeholder="" >
              {(src, loading) => {
                return (loading ? <div style={{maxHeight: "50px"}}><Spinner/></div> : 
                <div>
                  <CardImg className={classes.cardimg} width="20%" src={src} alt="me0"/>
                </div>
                );
              }}
            </ProgressiveImage>
          </div>
          <div className={classes.cardimgWrap + ' col-4'}>
            <ProgressiveImage src='./assets/me0.jpg' placeholder="" >
              {(src, loading) => {
                return (loading ? <div style={{maxHeight: "50px"}}><Spinner/></div> : 
                <div>
                  <CardImg className={classes.cardimg}  src={src} alt="me2"/>
                </div>
                );
              }}
            </ProgressiveImage>
          </div>
          <div className={classes.cardimgWrap + ' col-4'}>
            <ProgressiveImage src='./assets/me1.jpg' placeholder="" >
              {(src, loading) => {
                return (loading ? <div style={{maxHeight: "50px"}}><Spinner/></div> : 
                <div>
                  <CardImg className={classes.cardimg} width="20%" src={src} alt="me1"/>
                </div>
                );
              }}
            </ProgressiveImage>
          </div>
        </div>
        <CardBody>
          <CardTitle>
            <h1 className={classes.intro_h1}>Berlin Cho</h1>
          </CardTitle>
          <CardSubtitle>
            <h3 className={classes.intro_h3}>
              Software Developer | CS @ UIUC | Ex-intern @ Meta
            </h3>
          </CardSubtitle>
          <hr className={classes.intro_hr} />
          <CardText tag={'span'} className={classes.cardtext} data-aos="fade-left">
            <p>
              Hi there! My name is Po-Lin (Berlin) Cho. I'm from Taiwan and currently pursuing a Master's in Computer Science at <a href="https://illinois.edu">University of Illinois Urbana-Champaign</a>.
            </p>
            <p>
              I previously worked as a software engineer intern at <a href="https://about.facebook.com/">Meta (Facebook)</a>. 
              My work on Facebook CRM programs management, which accounts for more than 75% of Facebook revenue, enables global business users in better managing 220K+ advertiser assignments and adjusting to changing market conditions.
            </p>
            <p>
              Besides industrial experience, I used to conduct machine learning research for the topic of social networks at <a href="https://www.sinica.edu.tw/en">Academia Sinica (National Academy in Taiwan)</a>.
              I independently designed an entire graph model using PyTorch to approach the node classification task on social graphs. 
              Moreover, I contributed to open source software <a href="https://github.com/OpenAPITools/openapi-generator">OpenAPI Generator</a> and the GraphML file format module in <a href="https://github.com/networkx/networkx">networkx</a>, the most prevalent Python library for network analysis with 11.1k stars on GitHub.
            </p>
            <p>
              I am seeking 2023 Full-Time Software Engineer positions in the US. Feel free to contact me by <a href="mailto:contact@berlincho.com">email</a> for any opportunities.
            </p>
          </CardText>
          <Link to="/about">
            <Button className={classes.intro_btn}>
              <span className="mx-2">About Me</span>
            </Button>
          </Link>
        </CardBody>
      </Card>
    </div>
  );
};

export default Intro;
