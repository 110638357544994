import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import classes from './NewPost.module.css';

class NewPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      image: null,
      content: '',
      private: false,
      postSubmit: {
        submitted: false,
        success: false,
      },
    };
    this.modalToggler = this.modalToggler.bind(this);
    // form input handler
    this.handleImagePreview = this.handleImagePreview.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  modalToggler() {
    this.setState((prevState) => ({
      postSubmit: {
        submitted: !prevState.postSubmit.submitted,
      },
    }));
    this.props.history.push('/blogs');
  }

  handleImagePreview(event) {
    if (event.target.files.length === 0) {
      this.setState({
        image: null,
      });
    } else {
      this.setState({
        //image: URL.createObjectURL(event.target.files[0])
        image: event.target.files[0],
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      let formData = new FormData();
      formData.append('image', this.state.image);
      formData.append('title', this.state.title);
      formData.append('description', this.state.description);
      formData.append('content', this.state.content);
      formData.append('private', this.state.private);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: true,
      };
      await axios.post('/api/blogs', formData, config);
      this.setState({
        postSubmit: {
          submitted: true,
          success: true,
        },
      });
    } catch(err) {
      console.log(err);
      this.setState({
        postSubmit: {
          submitted: true,
          success: false,
        },
      });
    }
  }
  render() {
    if (!this.props.currentUser.authenticated) {
      return (
        <div className={classes.newPost + ' container content'}> </div>
      );
    }
    return (
      <div className={classes.newPost + ' container content'}>
        <div className="row mt-4">
          <div className="col-12 col-sm-8">
            <h1 className={classes.title}>New Post</h1>
          </div>
          <div className="col col-sm">
            <Button tag={Link} to="/blogs" color="primary" size="lg" className="mt-3 mt-sm-0">
              Return To Blog
            </Button>
          </div>
        </div>
        <hr />
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label className={classes.formLabel} for="title">
              Title
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Title"
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="title">
              Description
            </Label>
            <Input
              type="text"
              name="description"
              id="description"
              placeholder="Enter Description"
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="content">
              Post Content
            </Label>
            <Input
              type="textarea"
              name="content"
              id="content"
              placeholder="Enter Content"
              rows="20"
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className={classes.formLabel} for="image">
              Image
            </Label>
            <Input
              type="file"
              name="image"
              id="image"
              onChange={this.handleImagePreview}
              required
            />
            <FormText color="red">Upload image for this post.</FormText>
            <img
              src={
                this.state.image ? URL.createObjectURL(this.state.image) : null
              }
              alt=""
              style={{ width: '300px' }}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="private"
                id="private"
                onChange={this.handleChange}
              />{' '}
              Private Post
            </Label>
          </FormGroup>
          <Button className="mt-3 mb-5">Submit</Button>
          <Button tag={Link} to='/blogs' color="danger" className="mt-3 mb-5" style={{float:'right'}}>Cancel</Button>
        </Form>
        <Modal
          isOpen={this.state.postSubmit.submitted}
          toggle={this.modalToggler}
        >
          <ModalHeader toggle={this.modalToggler}>Post Submit Information</ModalHeader>
          <ModalBody>
            {this.state.postSubmit.success ? 'Successful!' : 'Failed!'}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}
export default connect(mapStateToProps)(withRouter(NewPost));
