import React, { Suspense } from 'react';
import Spinner from "../Spinner/Spinner";

const Loading = () => {
  return (
    <div className="container content">
      <Spinner />
    </div>
  );
};

const Home = () => {
  const HomeComponent = React.lazy(() => import('../Home/HomeComponent'));
  return (
    <Suspense fallback={<Loading/>}>
      <HomeComponent />
    </Suspense>
  );
}

const About = () => {
  const AboutComponent = React.lazy(() => import('../About/AboutComponent'));
  return (
    <Suspense fallback={<Loading/>}>
      <AboutComponent />
    </Suspense>
  );
}

const Blog = () => {
  const BlogComponent = React.lazy(() => import('../Blog/BlogComponent/BlogComponent'));
  return (
    <Suspense fallback={<Loading/>}>
      <BlogComponent />
    </Suspense>
  );
}

const NewPost = () => {
  const NewPostComponent = React.lazy(() => import('../Blog/NewPost/NewPost'));
  return (
    <Suspense fallback={<Loading/>}>
      <NewPostComponent />
    </Suspense>
  );
}

const Post = () => {
  const PostComponent = React.lazy(() => import('../Blog/Post/PostComponent'));
  return (
    <Suspense fallback={<Loading/>}>
      <PostComponent />
    </Suspense>
  );
}

const EditPost = () => {
  const EditPostComponent = React.lazy(() => import('../Blog/EditPost/EditPost'));
  return (
    <Suspense fallback={<Loading/>}>
      <EditPostComponent />
    </Suspense>
  );
}

const URLshorter = () => {
  const URLshorterComponent = React.lazy(() => import('../Project/UrlShorter/UrlShorter'));
  return (
    <Suspense fallback={<Loading/>}>
      <URLshorterComponent />
    </Suspense>
  );
}

const URLredirect = () => {
  const URLredirectComponent = React.lazy(() => import('../Project/UrlShorter/UrlRedirect/UrlRedirect'));
  return (
    <Suspense fallback={<Loading/>}>
      <URLredirectComponent />
    </Suspense>
  );
}

export {
  Home, About, Blog, NewPost, Post, EditPost, URLshorter, URLredirect
};
