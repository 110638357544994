import React from 'react';
import { connect } from 'react-redux';
import { addCurrentUser } from '../../../redux/ActionCreators';
import PostMedia from './PostMedia/PostMedia'
import axios from 'axios';
import { Button } from 'reactstrap';
import classes from './BlogComponent.module.css';
import { Link } from 'react-router-dom';
import Spinner from '../../Spinner/Spinner';
import LoginButton from '../../Login/LoginComponent';
//import Flash from '../../Flash/Flash';
class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
    this.deleteHandler = this.deleteHandler.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.posts.length === nextState.posts.length && nextProps.currentUser.authenticated === this.props.currentUser.authenticated){
      return false;
    } else {
      return true;
    }
  }

  async componentDidMount() {
    try {
      if (!this.props.currentUser.authenticated) {
        this.props.onAddCurrentUser();
      }
      //Fetch posts data
      const blogsRes = await axios.get('/api/blogs');
      this.setState({
        posts: blogsRes.data.posts,
      });
      
    } catch(err) {
      console.log(err);
    }
  }

  async deleteHandler (id) {
    try {
      const config = {
        headers: {
          'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: true,
      };
      await axios.delete('/api/blogs/' + id, config);
      this.setState(prevState => ({     	
        posts: prevState.posts.filter(post => post._id !== id)    
       }));
      console.log(id + ' Successfully Deleted!');
    } catch(err) {
      console.log(err);
    }
  }

  render() {
    if (this.state.posts.length === 0) {
      return (
        <div className="container content">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div className={classes.Blog + ' container content'}>
          {/*this.state.login.authenticated? <Flash duration={1500} message={this.state.login.message}/> : null*/}
          <div className="row mt-4">
            <div className="col-12 col-sm-8">
              <h1 className={classes.title}>Po-Lin's Blog</h1>
            </div>
            <div className="col-6 col-sm-2">
              <Button style={{display: this.props.currentUser.authenticated ? 'inline-block' : 'none' }} tag={Link} to="/blogs/new_post" color="primary" size="lg" className="mt-3 mt-sm-0">
                New Post
              </Button>
            </div>
            <div className="col-6 col-sm">
              <LoginButton login={this.props.currentUser.authenticated} />
            </div>
          </div>
          <div className={classes.posts}>
            <PostMedia posts={this.state.posts} login={this.props.currentUser.authenticated} deleteHandler={this.deleteHandler}/>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onAddCurrentUser: () => dispatch(addCurrentUser()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
