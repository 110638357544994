import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import dompurify from 'dompurify';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ProgressiveImage from "react-progressive-graceful-image";
import Spinner from '../../Spinner/Spinner';
import classes from './PostComponent.module.css';
import 'highlight.js/styles/github.css';
import 'katex/dist/katex.min.css';
import './github-markdown.css';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
    };
    this.deleteHandler = this.deleteHandler.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.post.createdAt === nextState.post.createdAt && nextProps.currentUser.authenticated === this.props.currentUser.authenticated){
      return false;
    } else {
      return true;
    }
  }
  async componentDidMount(event) {
    try {
      const id = this.props.match.params.id;
      //Fetch post data
      const postRes = await axios.get('/api/blogs/' + id);
      if (postRes.data.post) {
        this.setState({ 
          post: postRes.data.post,
        });
      } else {
        this.props.history.push('/blogs');
      }
    } catch(err) {
      console.log(err);
    }
  }

  async deleteHandler (id) {
    try {
      const config = {
        headers: {
          'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: true,
      };
      await axios.delete('/api/blogs/' + id, config);
      this.props.history.push("/blogs");
      console.log(id + ' Successfully Deleted!');
    } catch(err) {
      console.log(err);
    }
  }

  render() {
    const sanitizer = dompurify.sanitize;
    const post = this.state.post;
    const updatedDiff = (post.updatedAt)?Math.floor((Date.now() - new Date(post.updatedAt).getTime()) / (1000*3600*24)) : null
    console.log(updatedDiff)
    if (post){
      return (
        <div className={classes.Post + ' container content'}>
          <ProgressiveImage   src={post.image ? post.image.link : null} placeholder="" >
            {(src, loading) => {
              return loading ? <div style={{height: '300px', paddingTop: '40px'}}><Spinner/></div> : 
              <img className={classes.image + " col-10 col-md-7"} src={src} alt="post_image"/>;
            }}
          </ProgressiveImage>
          <div className={classes.title}>
            <h1>{post.title}</h1>
            <h5>{post.description}</h5>
            <p className={'mt-3 ' + classes.created}>
              {post.createdAt? new Date(post.createdAt).toString().substring(0, 15):null}
            </p>
            <p className={classes.created} style={{fontStyle: "italic"}}>
              {(post.updatedAt)? 'Updated ' + updatedDiff + ' days ago' : null}
            </p>
          </div>
          <div className={classes.buttons}>
            <Button tag={Link} to="/blogs" color="primary" size="sm">
              Return To Blog
            </Button>
            <Button style={{display: this.props.currentUser.authenticated ? 'inline' : 'none' }} tag={Link} to={this.props.location.pathname+"/edit"} className="mx-1" color="danger" size="sm">
              Edit
            </Button>
            <Button style={{display: this.props.currentUser.authenticated ? 'inline' : 'none' }} color="danger" size="sm" onClick={() => {this.deleteHandler(post._id)}}>
              Delete
            </Button>
          </div>
          <div
            className="markdown-body"
            dangerouslySetInnerHTML={{
              __html: sanitizer(post.content),
            }}
          ></div>
        </div>
      );
    } else {
      return (
        <div className='container content'>
          <Spinner />
        </div>
      );
    }
    
  }
}
const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}
export default connect(mapStateToProps)(withRouter(Post));
