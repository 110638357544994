import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import AOS from 'aos';
import 'aos/dist/aos.css';
import classes from './WorkComponent.module.css';
/*
props.work =[
  company: 'Interuniversity Microelectronics Centre Belgium (IMEC), Taiwan Office',
  location: 'Hsinchu, Taiwan',
  title: 'R&D Software Engineering Intern',
  period: 'July 2018 - Aug. 2018',
  contributions: []
]
*/
AOS.init({
  duration: 1200,
});

const work = (props) => {
  const contributions = props.work.contributions.map((con) => {
    return <li key={con.id}>{con.content}</li>;
  });
  return (
    <div className="about-work" data-aos="fade-up">
      <Card className={classes.card}>
        <CardBody>
          <CardTitle className={classes.cardTitle}>
            <BrowserView>{props.work.companyBrowser}</BrowserView>
            <MobileOnlyView>{props.work.companyMobile}</MobileOnlyView>
            <span className={classes.location}>{props.work.location}</span>
          </CardTitle>
          <CardSubtitle className={classes.cardSubTitle + ' row'}>
            <span>{props.work.title}</span>
            <span className={classes.period + ' d-none d-md-inline'}>
              {props.work.period}
            </span>
          </CardSubtitle>
          <ol className={classes.cardText}>{contributions}</ol>
        </CardBody>
      </Card>
    </div>
  );
};

export default work;
