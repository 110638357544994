import React from 'react';
import classes from './RGCNComponent.module.css';

const RGCN = (props) => {
  return (
    <div className={classes.rgcn + ' container'}>
      <div className="row">
        <h1 className="col-12">Relational Graph Convolutional Networks</h1>
        <h4 className="col-12">Pytorch implementation</h4>
      </div>

      <hr></hr>

      <div className={classes.github}>
        <i className="fa fa-github fa-2x mr-2"></i>
        <a href="https://github.com/berlincho/RGCN-pytorch">
          https://github.com/berlincho/RGCN-pytorch
        </a>
      </div>

      <div className={classes.content + ' row ml-5'}>
        <div className="col-md-2"></div>
        <div className="col-md-8 col-11">
          <p>
            Pytorch-based implementation of R-GCN for semi-supervised node
            classification on (directed) relational graphs. The code is adpated
            from{' '}
            <a href="https://github.com/tkipf/relational-gcn">
              Kipf’s Keras-based implementation
            </a>
            . See details of R-GCN in{' '}
            <a href="https://arxiv.org/abs/1703.06103">
              Modeling Relational Data with Graph Convolutional Networks (2017)
            </a>{' '}
            [1].
          </p>
          <hr/>
          <ul>
            <li>
              [1] Schlichtkrull, M., Kipf, T., Bloem, P., Berg, R.V., Titov, I., & Welling, M. (2018). Modeling Relational Data with Graph Convolutional Networks. ArXiv, abs/1703.06103.
            </li>
            <li>
              [2] Vicente, M. (2019). Graph Neural Networks and its applications.
            </li>
          </ul>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};
export default RGCN;
