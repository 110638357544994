import React from 'react';
import ProjectData from './ProjectData';
import ProjectChild from './ProjectChild/ProjectChild';

import classes from './ProjectOverviewComponent.module.css';

class ProjectMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={classes.ProjectOverview + ' container content'}>
        <h1 className={classes.title}>Project List</h1>
        <ul>
          <ProjectChild projects={ProjectData} />
        </ul>
      </div>
    );
  }
}
export default ProjectMain;
