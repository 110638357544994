import React from 'react';
import dompurify from 'dompurify';
import { Media, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classes from './PostMedia.module.css';
import ProgressiveImage from "react-progressive-graceful-image";
import Spinner from '../../../Spinner/Spinner';

const PostMedia = (props) => {
  let Posts = props.posts;
  const sanitizer = dompurify.sanitize;
  const PostsMap = Posts.map((post) => {
    return (
      <Media className={classes.PostMedia + ' row'} key={post._id}>
          <div className={"col-12 col-md-3"}>
            <Media
              left
              tag={Link}
              to={'/blogs/' + post._id}
            >
              <ProgressiveImage src={post.image ? post.image.link : null} placeholder="" >
                {(src, loading) => {
                  return loading ? <div><Spinner/></div> : 
                  <div><Media object src={src}  alt="image"/></div>;
                }}
              </ProgressiveImage>
            </Media>
          </div>
          <div className="col-12 col-md-9 mt-4 mt-md-0">
            <Media body>
              <Media heading className={classes.postTitle}>
                {post.title}
              </Media>
                <p className={classes.postCreated}>
                  {new Date(post.createdAt).toString()}
                </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    post.description.substring(0, 150) + '...'
                  ),
                }}
              />
              <div className="row">
                <div className="col-12 col-md-8">
                  <Button
                    tag={Link}
                    to={'/blogs/' + post._id}
                    className="mt-2"
                    outline
                    color="primary"
                    size="sm"
                  >
                    Read More >
                  </Button>
                </div>
                <div className="col col-md">
                <Button
                    style={{display: props.login ? 'inline-block' : 'none' }}
                    tag={Link}
                    to={'/blogs/' + post._id + '/edit'}
                    className="mt-2 mr-1"
                    color="danger"
                    size="sm"
                  >
                  Edit
                  </Button>
                  <Button
                    style={{display: props.login ? 'inline-block' : 'none' }}
                    className="mt-2"
                    color="danger"
                    size="sm"
                    onClick={() => {props.deleteHandler(post._id)}}
                  >
                  Delete
                  </Button>
                </div>
              </div>
            </Media>
          </div>
      </Media>
    );
  });
  return (
    <div>
      {PostsMap}
    </div>
  );
}
export default PostMedia;