import React from 'react';
import { deleteCurrentUser } from '../../redux/ActionCreators';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

class AuthButton extends React.Component {
  _handleSignInClick = (e) => {
    window.open("/api/auth/facebook", "_self");
    e.preventDefault();
  };

  _handleLogoutClick = (e) => {
    window.open("/api/auth/logout", "_self");
    this.props.onDeleteCurrentUser();
    e.preventDefault();
  };

  render() {
    if (this.props.login) {
      return (
        <Button color="success" size="md" className="mt-3 mt-sm-0" style={{float: 'right'}} onClick={this._handleLogoutClick}>
          Logout
        </Button>
      ) 
    }else {
      return (
        <Button color="success" size="md" className="mt-3 mt-sm-0" style={{float: 'right'}} onClick={this._handleSignInClick}>
          Login
        </Button>
      );
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDeleteCurrentUser: () => dispatch(deleteCurrentUser()),
  }
}

export default connect(null, mapDispatchToProps)(AuthButton); 
