import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import classes from './Extracurricular.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  duration: 1200,
});

/*
props.work =[
  company: 'Interuniversity Microelectronics Centre Belgium (IMEC), Taiwan Office',
  location: 'Hsinchu, Taiwan',
  title: 'R&D Software Engineering Intern',
  period: 'July 2018 - Aug. 2018',
  contributions: []
]

*/
const Extracurricular = (props) => {
  const contributions = props.extracurricular.contributions.map((con) => {
    return <li key={con.id}>{con.content}</li>;
  });
  return (
    <div data-aos="fade-up">
      <Card className={classes.card}>
        <CardBody>
          <CardTitle className={classes.cardTitle}>
            {props.extracurricular.organization}
          </CardTitle>
          <CardSubtitle className={classes.cardSubTitle}>
            <span>{props.extracurricular.position}</span>
            <span className={classes.period + ' d-none d-md-inline'}>
              {props.extracurricular.period}
            </span>
          </CardSubtitle>
          <ol className={classes.cardText}>{contributions}</ol>
        </CardBody>
      </Card>
    </div>
  );
};

export default Extracurricular;
