import * as ActionTypes from '../ActionTypes';

const initialUser = {
  authenticated: false,
  message: '',
  user: null,
};

export const User = (state=initialUser, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CURRENT_USER:
      return { ...state, authenticated: action.authenticated, message: action.message, user: action.user }
    case ActionTypes.DELETE_CURRENT_USER:
      return { ...state, authenticated: false, message: '', user: null }
    default:
      return {...state}
  }
}
