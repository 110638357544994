import React, { Suspense } from 'react';
import Spinner from '../../Spinner/Spinner';
import RGCN from '../RGCN/RGCNComponent';
import GCNwithKD from '../GCNwithKD/GCNwithKD';
import SerialPort from '../SerialPort/SerialPortComponent';
import ProjectOverview from '../ProjectOverview/ProjectOverviewComponent';
const ProjectDistributed = (props) => {
  const Patatap = React.lazy(() =>
    import('../Patatap/ProjectComponent/ProjectComponent')
  );

  if (props.match.params.id === 'patatap') {
    return (
      <Suspense
        fallback={
          <div className="container content">
            <Spinner />
          </div>
        }
      >
        <Patatap />
      </Suspense>
    );
  } else if (props.match.params.id === 'rgcn-pytorch') {
    return <RGCN />;
  } else if (props.match.params.id === 'serial-port-gui') {
    return <SerialPort />;
  } else if (props.match.params.id === 'gcn-with-hinton-knowledge-distillation'){
    return <GCNwithKD />
  } else {
    return <ProjectOverview />;
  }
};
export default ProjectDistributed;
