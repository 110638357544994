import React from 'react';
import { Media } from 'reactstrap';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import AOS from 'aos';
import 'aos/dist/aos.css';
import classes from './SchoolComponent.module.css';

AOS.init({
  duration: 1200,
});

const School = (props) => (
  <div className={classes.school}>
    <Media className="row">
      <Media left href="https://cs.illinois.edu/" className="col-sm-2 col-12">
        <Media
          data-aos="fade-right"
          className="d-none d-sm-inline"
          object
          src="./assets/uiuc.png"
          alt="UIUC image"
          style={{ width: '50%', height: 'auto', marginLeft: '1.9em' }}
        />
      </Media>
      <Media body className="col-md-10 col-12 ml-3">
        <BrowserView>
          <Media heading className="SchoolName">
            University of Illinois at Urbana-Champaign (UIUC), USA
          </Media>
          <ul>
            <li>Master of Computer Science</li>
            <li>Overall GPA: 4.0/4.0</li>
            <li>Expect Graduation Date: December 2022</li>
            <li>Graduate Teaching Assistantship: CS498 Cloud Computing Applications</li>
          </ul>
        </BrowserView>
        <MobileOnlyView>
          <div data-aos="fade-right">
            <Media heading className="SchoolName">
              University of Illinois at Urbana-Champaign, USA
            </Media>
            <ul>
              <li>Master of Computer Science</li>
              <li>Overall GPA: 4.0/4.0</li>
              <li>Expect Graduation Date: Dec. 2022</li>
              <li>Graduate Teaching Assistantship: CS498 Cloud Computing Applications</li>
            </ul>
          </div>
        </MobileOnlyView>
      </Media>
    </Media>
    <Media className="row mt-5">
      <Media
        left
        href="https://www.cs.nctu.edu.tw/"
        className="col-sm-2 col-12"
      >
        <Media
          data-aos="fade-right"
          className="d-none d-sm-inline"
          object
          src="./assets/nctu.png"
          alt="NCTU image"
          style={{ width: '85%', height: 'auto', marginLeft: '1.2em' }}
        />
      </Media>
      <Media body className="col-sm-10 col-12 pt-2 ml-3">
        <BrowserView>
          <Media heading className="SchoolName">
            National Chiao Tung University (NCTU), Taiwan
          </Media>
          <ul>
            <li>Bachelor of Science in Computer Science</li>
            <li>Overall GPA: 3.86/4.30, Last 60 GPA: 4.14/4.30</li>
            <li>Graduation Date: June 2018</li>
          </ul>
        </BrowserView>
        <MobileOnlyView>
          <div data-aos="fade-right">
            <Media heading className="SchoolName">
              National Chiao Tung University, Taiwan
            </Media>
            <ul>
              <li>Bachelor of Science in Computer Science</li>
              <li>Overall GPA: 3.86/4.30, Last 60 GPA: 4.14/4.30</li>
              <li>Graduation Date: June 2018</li>
            </ul>
          </div>
        </MobileOnlyView>
      </Media>
    </Media>
  </div>
);

export default School;
