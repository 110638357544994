import React from 'react';
import classes from './FooterComponent.module.css';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  render() {
    return (
      <div className={classes.Footer}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-4 offset-1 col-sm-2">
              <h5>Links</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    onMouseOver={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    toggle={this.toggle}
                  >
                    <DropdownToggle tag={Link} to="/projects" caret>
                      Projects
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        className={classes.dropdownItem}
                        tag={Link}
                        to="/url"
                      >
                        Simple Url Shortener
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        tag={Link}
                        to="/projects/serial-port-gui"
                      >
                        Serial Port GUI
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        tag={Link}
                        to="/projects/gcn-with-hinton-knowledge-distillation"
                      >
                        GCN with vanilla Knowledge Distillation
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        tag={Link}
                        to="/projects/rgcn-pytorch"
                      >
                        R-GCN (Pytorch)
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        href="https://www.yichichu.com"
                      >
                        Yichichu - Event Calendar
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        href="https://charmbo-dating-app.herokuapp.com/"
                      >
                        Charmbo - Dating App
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        href="https://github.com/berlincho/e-commerce-ticketing"
                      >
                        E-commerce Ticketing System
                      </DropdownItem>
                      <DropdownItem
                        className={classes.dropdownItem}
                        tag={Link}
                        to="/projects/patatap"
                      >
                        Sound Effects
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-7 col-sm-5 justify-content-center">
              <h5>My Address</h5>
              <address>
                Thomas M. Siebel Center for Computer Science
                <br />
                201 North Goodwin Avenue
                <br />
                Urbana, IL 61801-2302
                <br />
                USA
                <br />
                <i className="fa fa-envelope fa-lg"></i>:{' '}
                <a href="mailto:contact@berlincho.com">
                  contact@berlincho.com
                </a>
              </address>
            </div>
            <div className="col-12 col-sm-4">
              <div className="text-center">
                <div className="row justify-content-center">
                  <a href="https://drive.google.com/file/d/1suSLmhnNdV4VoeSNvqD9TRdAxpTA6gjo/view?usp=sharing">
                    <Button className="my-3">Download My Resume</Button>
                  </a>
                </div>
                <div className="row justify-content-center">
                  <a
                    className="btn btn-social-icon btn-facebook mr-1"
                    href="https://www.facebook.com/berlincho"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    className="btn btn-social-icon btn-linkedin mr-1"
                    href="http://www.linkedin.com/in/berlincho"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    className="btn btn-social-icon btn-twitter mr-1"
                    href="http://twitter.com/berlinchoose"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>

                  <a
                    className="btn btn-social-icon btn-success mr-1"
                    href="mailto:contact@berlincho.com"
                  >
                    <i className="fa fa-envelope-o"></i>
                  </a>
                  <a
                    className="btn btn-social-icon btn-github btn-success"
                    href="https://github.com/berlincho"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <p>Copyright © 2021 Berlin Cho</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
