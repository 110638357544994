import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import classes from './HeaderComponent.module.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.navbarToggle = this.navbarToggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.navLinkToggle = this.navLinkToggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      navbarIsOpen: false,
      dropdownOpen: false,
    };
  }
  closeNavbar() {
    this.setState({
      navbarIsOpen: false,
    });
  }

  navbarToggle() {
    this.setState((state) => {
      return {
        navbarIsOpen: !state.navbarIsOpen,
      };
    });
  }

  navLinkToggle() {
    this.setState((state) => {
      return {
        dropdownOpen: !state.dropdownOpen,
      };
    });
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  render() {
    return (
      <div>
        <Navbar className={classes.Navbar} sticky="top" dark expand="md">
          <div className="container">
            <NavbarBrand
              className={classes.NavbarBrand}
              onClick={this.closeNavbar}
              tag={RRNavLink}
              to="/"
            >
              Berlin's
            </NavbarBrand>
            <NavbarToggler onClick={this.navbarToggle} />
            <Collapse isOpen={this.state.navbarIsOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink
                    id={classes.NavLink}
                    onClick={this.closeNavbar}
                    tag={RRNavLink}
                    to="/"
                    exact
                    activeStyle={{
                      background: 'rgba(226, 244, 244, 0.2)',
                    }}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    id={classes.NavLink}
                    onClick={this.closeNavbar}
                    tag={RRNavLink}
                    to="/about"
                    activeStyle={{
                      background: 'rgba(226, 244, 244, 0.2)',
                    }}
                  >
                    About
                  </NavLink>
                </NavItem>
                <Dropdown
                  nav
                  inNavbar
                  isOpen={this.state.dropdownOpen}
                  onMouseOver={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                  toggle={this.navLinkToggle}
                >
                  <DropdownToggle
                    id={classes.NavLink}
                    onClick={this.closeNavbar}
                    tag={RRNavLink}
                    to="/projects"
                    nav
                    caret
                    activeStyle={{
                      background: 'rgba(226, 244, 244, 0.2)',
                    }}
                  >
                    Projects
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      tag={RRNavLink}
                      to="/projects/patatap"
                    >
                      Sound Effects
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      href="https://github.com/berlincho/e-commerce-ticketing"
                    >
                      E-commerce Ticketing System
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      href="https://charmbo-dating-app.herokuapp.com"
                    >
                      Charmbo - Dating App
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      href="https://www.yichichu.com"
                    >
                      Yichichu - Event Calendar
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      tag={RRNavLink}
                      to="/projects/rgcn-pytorch"
                    >
                      R-GCN (Pytorch)
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      tag={RRNavLink}
                      to="/projects/gcn-with-hinton-knowledge-distillation"
                    >
                      GCN with vanilla Knowledge Distillation
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      tag={RRNavLink}
                      to="/projects/serial-port-gui"
                    >
                      Serial Port GUI
                    </DropdownItem>
                    <DropdownItem
                      className={classes.dropdownItem}
                      onClick={this.closeNavbar}
                      tag={RRNavLink}
                      to="/url"
                    >
                      Simple Url Shortener
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <NavItem>
                  <NavLink
                    id={classes.NavLink}
                    onClick={this.closeNavbar}
                    tag={RRNavLink}
                    to="/blogs"
                    activeStyle={{
                      background: 'rgba(226, 244, 244, 0.2)',
                    }}
                  >
                    Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    id={classes.NavLink}
                    onClick={this.closeNavbar}
                    tag={RRNavLink}
                    to="/contact"
                    activeStyle={{
                      background: 'rgba(226, 244, 244, 0.2)',
                    }}
                  >
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
              <a href="https://drive.google.com/file/d/1suSLmhnNdV4VoeSNvqD9TRdAxpTA6gjo/view?usp=sharing">
                <Button>Download My Resume</Button>
              </a>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default Header;
