import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import ScrollToTopButton from "../ScrollToTop/ScrollToTopButton";
import Header from '../Header/HeaderComponent';
import Footer from '../Footer/FooterComponent';
import Home from '../Home/HomeComponent';
import About from '../About/AboutComponent';
import Blog from '../Blog/BlogComponent/BlogComponent';
import NewPost from '../Blog/NewPost/NewPost';
import Post from '../Blog/Post/PostComponent';
import EditPost from '../Blog/EditPost/EditPost';
import { URLshorter, URLredirect} from './MainComponentLazyLoad';
//import {
//  Home, About, Blog, NewPost, Post, EditPost
//} from './MainComponentLazyLoad';
import ProjectOverview from '../Project/ProjectOverview/ProjectOverviewComponent';
import ProjectDistributed from '../Project/ProjectDistributed/ProjectDistributed';
import Contact from '../Contact/ContactComponent';
import { actions } from 'react-redux-form';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => ({
  resetFeedbackForm: () => {
    dispatch(actions.reset('feedback'));
  },
});

class Main extends React.Component {
  render() {
    return (
      <div className="bg-img">
        <Header />
        <ScrollToTop>
          <Switch>
            <Route
              path="/contact"
              component={() => (
                <Contact resetFeedbackForm={this.props.resetFeedbackForm} />
              )}
            ></Route>
            <Route path="/blogs/new_post" component={() => <NewPost />}></Route>
            <Route path="/blogs/:id/edit">{() => <EditPost />}</Route>
            <Route exact path="/blogs/:id">{() => <Post />}</Route>
            <Route path="/blogs" component={() => <Blog />}></Route>
            <Route path="/projects" exact component={ProjectOverview}></Route>
            <Route path="/projects/:id" component={ProjectDistributed}></Route>
            <Route path="/about" component={() => <About />}></Route>
	    <Route path="/url" exact component={URLshorter}></Route>
            <Route path="/url/:id" component={URLredirect}></Route>
            <Route path="/" component={() => <Home />}></Route>
            <Redirect to="home" />
          </Switch>
        </ScrollToTop>
        <Footer />
        <ScrollToTopButton
          text="TOP"
          distance={100}
          breakpoint={768}
          className="scroll-your-role"
          speed={200}
          target={0}
        />
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Main));
