import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import classes from './ProjectChild.module.css';

const Tag = (props) => {
    const tag = props.tag.map((tag, i) => {
    let color = 'success'
    if (i === 2) {
      //last one is important
      color = 'danger'
    }
    return (
      <Badge style={{fontSize: '12px'}} key={i} color={color} className="mr-2">{tag}</Badge>
    );
  });
  return tag;
}

const ProjectChild = (props) => {
  
  const Project = props.projects.map(project => {
    return (
      <li key={project.id}>
        <div className={classes.ProjectChild + " row"}>
          <div className="col-12 col-lg-3">
            {project.route?
              <Link to={project.route}>
                <img src={project.image} alt="projectImg"></img>
              </Link> :
              <a href={project.github? project.github : project.link}>
                <img src={project.image} alt="projectImg"></img>
              </a>
            }
            
          </div>
          <div className={classes.content + " col-12 col-lg-9"}> 
              <h5>{project.title}</h5>
              <Tag tag={project.tag} />
              <p>{project.description}  
                {project.route ? <Link to={project.route}><span className={classes.link + " fa fa-link fa-lg"}> </span></Link> : null}
                {project.link ? <a href={project.link}><span className={classes.link + " fa fa-link fa-lg"}> </span></a> : null}
                {project.github ? <a href={project.github}><span className={classes.link + " fa fa-github fa-lg"}> </span></a> : null}
              </p>
          </div>
        </div>
      </li>
    );
  });
  return Project
};

export default ProjectChild;