import React from 'react';
import classes from './SerialPortComponent.module.css';
import ProgressiveImage from "react-progressive-graceful-image";
import Spinner from '../../Spinner/Spinner';

const SerialPort = (props) => {
  return (
    <div className={classes.serialport + ' container content'}>
      <div className="row">
        <div className="col-12">
          <h1>Serial Port Graphical User Interface (GUI)</h1>
          <h4>Python Tkinter implementation</h4>
        </div>
      </div>
      <hr></hr>
      <div className={classes.github}>
        <i className="fa fa-github fa-2x mr-2"></i>
        <a href="https://github.com/berlincho/serial-port-GUI">
          https://github.com/berlincho/serial-port-GUI
        </a>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mb-md-5">
          <ProgressiveImage src="https://raw.githubusercontent.com/berlincho/serial-port-GUI/master/figures/serialport.jpeg" placeholder="" >
            {(src, loading) => {
              return loading ? <div><Spinner/></div> : 
              <img className={classes.image} src={src} alt="serial port"></img>;
            }}
          </ProgressiveImage>
        </div>
        <div className={classes.content + ' col-12 col-md-6 mb-md-5'}>
          <h5>Basic features are described below:</h5>
          <ul>
            <li>Scan Device</li>
            <li>Open Device / Close Device</li>
            <li>Send File</li>
            <li>Send input command</li>
            <li>Horizontal and Vertical Scrollbar for logging</li>
            <li>Save logs</li>
            <li>Clear logs</li>
            <li>More...</li>
          </ul>
        </div>
        
      </div>
    </div>
  );
};
export default SerialPort;
