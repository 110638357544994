import React from 'react';
import classes from './AboutComponent.module.css';

import Work from './Work/WorkComponent';
import Skill from './Skill/SkillComponent';
import Extracurricular from './Extracurricular/Extracurricular';
import School from './School/SchoolComponent';

import workExperience from './Work/WorkData';
import ExtracurricularData from './Extracurricular/ExtracurricularData';

class About extends React.Component {
  render() {
    const work = workExperience.map((workexp) => {
      return <Work key={workexp['id']} work={workexp} />;
    });
    const extracurricular = ExtracurricularData.map((ex) => {
      return <Extracurricular key={ex['id']} extracurricular={ex} />;
    });
    return (
      <div className={classes.aboutContainer + ' container content'}>
        <h1>Skills</h1>
        <Skill />
        <h1>Work Expereience</h1>
        {work}
        <h1>Education</h1>
        <School />
        <h1>Selected Extracurricular</h1>
        {extracurricular}
      </div>
    );
  }
}

export default About;
