const ExtracurricularData = [
  {
    id: 1,
    organization: 'NCTU UU Club',
    position: 'Chief of Internet Publicity Section',
    period: 'Aug. 2015 - Aug. 2017',
    contributions: [
      { id: 1, content: 'A voluntary social service club for young children' },
      {
        id: 2,
        content:
          'Promoted volunteer activities through social media (i.e Facebook); for each posts, average 5000+ people reached.',
      },
      {
        id: 3,
        content:
          'Served as a volunteer to design lectures and summer camps for 200+ young children from low income families.',
      },
    ],
  },
  {
    id: 2,
    organization: 'Service Learning Center at NCTU',
    position: 'Community Volunteering Group Member',
    period: 'Feb. 2017 - June 2017',
    contributions: [
      {
        id: 1,
        content:
          'Developed front-end web application for the Hospice Center at National Taiwan University Hospital Hsinchu Branch using WordPress, HTML, and CSS.',
      },
      {
        id: 2,
        content:
          'Collected 50+ inspiring articles about hospice care for cancer patients.',
      },
      {
        id: 3,
        content:
          'Assisted physicians to promote the concepts of health care to the public.',
      },
    ],
  },
];

export default ExtracurricularData;
