import React from 'react';
import './Skillbar.css';
const SkillComponent = (props) => (
  <div className="row">
    <div className="col-md-6">
      <div className="bar purpleOne expert" data-skill="Python"></div>
      <div className="bar purpleTwo expert" data-skill="Javascript"></div>
      <div className="bar purpleThree expert" data-skill="NodeJS"></div>
      <div className="bar purpleFour expert" data-skill="C/C++"></div>
      <div className="bar basic expert" data-skill="HTML/CSS"></div>
    </div>
    <div className="col-md-6 skills mt-md-0 my-5">
      <div>
        <h5 className="mb-1">Artificial Intelligence</h5>
        <hr className="mt-0 mb-2" />
        <p>Pytorch, Tensorflow, Numpy, Scikit-Learn, Scipy</p>
      </div>
      <div>
        <h5 className="mb-1">Web Development</h5>
        <hr className="mt-0 mb-2" />
        <p>React, Redux, HTML/CSS, Bootstrap, Express, GraphQL, MongoDB, SQL</p>
      </div>
      <div>
        <h5 className="mb-1">System</h5>
        <hr className="mt-0 mb-2" />
        <p>Docker, Kubernetes, GCP (Google Certified Associate Cloud Engineer), AWS</p>
      </div>
    </div>
  </div>
);
export default SkillComponent;
