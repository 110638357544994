import React from 'react';
import Intro from './IntroComponent/IntroComponent';
import classes from './HomeComponent.module.css';

function Home() {
  return (
    <div className="container content">
      <div className={classes.homepage}>
        <div className="row">
          <div className="col-md-10 col-12 mx-auto">
            <Intro />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
