import * as ActionTypes from './ActionTypes';
import axios from 'axios';

export const addCurrentUser = () => {
  return dispatch => {
    const config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Credentials': 'true',
      },
      withCredentials: true,
    };
    axios.get('/api/auth/facebook/status', config).then(loginStatus => {
      //Aconsole.log(loginStatus.data);
      return dispatch({
        type: ActionTypes.ADD_CURRENT_USER,
        authenticated: loginStatus.data.authenticated,
        message: loginStatus.data.message,
        user: loginStatus.data.user,
      })
    }).catch(err => {
      console.log(err);
      return dispatch({
        type: ActionTypes.ADD_CURRENT_USER,
        authenticated: false,
        message: err.message,
        user: null,
      })
    })
  }
}

export const deleteCurrentUser = () => {
  return {
    type: ActionTypes.DELETE_CURRENT_USER
  }
}
