const workExperience = [
  {
    id: 1,
    companyBrowser: 'Meta (Facebook)',
    companyMobile: 'Meta (Facebook)',
    location: 'Menlo Park, California',
    title: 'Software Engineer Intern',
    period: 'May. 2022 - Aug. 2022',
    contributions: [
      {
        id: 1,
        content:
          'Enabled CSV upload of data on Facebook CRM to allow business users to view and manage >220K advertisers.',
      },
      {
        id: 2,
        content:
          'Utilized React, GraphQL, and Hack (PHP) to develop file upload error handling flow and trigger asynchronous jobs on remote servers.'
      },
      {
        id: 3,
        content:
          'Collaborated with 2 designers, global business partners and 5 software engineers to test and launch new features.',
      },
    ],
  },
  {
    id: 2,
    companyBrowser: 'Institute of Information Science, Academia Sinica',
    companyMobile: 'Academia Sinica',
    location: 'Taipei, Taiwan',
    title: 'Machine Learning Research Assistant',
    period: 'Sept. 2019 - June 2021',
    contributions: [
      {
        id: 1,
        content:
          'Improved 13.02% accuracy for semi-supervised node classification task in social networks by designing novel framework of Multi-relational Graph Convolutional Networks.',
      },
      {
        id: 2,
        content:
          'Contributed to GraphML file format module in Python Library for Network Analysis (networkx) with 9.4k stars on GitHub.'
      },
      //{
      //  id: 3,
      //  content:
      //    'Implemented Knowledge Distillation framework on the state-of-the-art Graph Convolution Network models (e.g. GCN, RGCN, and WGCN).',
      //},
      {
        id: 3,
        content:
          'Conducted peer reviews for top data mining conferences and journals (e.g. KDD, WSDM, PAKDD, and ACM CSUR).',
      },
    ],
  },
  {
    id: 3,
    companyBrowser: 'Interuniversity Microelectronics Centre (Imec)',
    companyMobile: 'Imec Taiwan',
    location: 'Hsinchu, Taiwan',
    title: 'Software Engineering Intern',
    period: 'July 2018 - Aug. 2018',
    contributions: [
      {
        id: 1,
        content:
          'Saved 5% battery consumption of smart watch by implementing GPS power management logic on ARM micro-controllers.',
      },
      {
        id: 2,
        content:
          'Improved code quality of short message service on smart watch with unit tests and integration tests.',
      },
      {
        id: 3,
        content:
          'Resolved critical non-deterministic data loss issues by fixing software bugs in Bluetooth Low Energy module.',
      }
    ],
  },
  {
    id: 4,
    companyBrowser: 'Kioxia Group (Toshiba Memory)',
    companyMobile: 'Kioxia (Toshiba Memory)',
    location: 'Hsinchu, Taiwan',
    title: 'Software Engineering Intern',
    period: 'July 2017 - Feb. 2018',
    contributions: [
      {
        id: 1,
        content:
          'Increased deployment efficiency by configuring fully automated CI/CD pipelines using GitLab, Jenkins, and Jira.',
      },
      {
        id: 2,
        content:
          'Enabled log monitoring by developing Non-Volatile Memory Express and Serial Port GUIs using Python Tkinter.',
      },
      {
        id: 3,
        content:
          'Deployed SAMBA Server (for 100+ engineers) to share SSD firmware soruce codes between Windows and Linux.',
      },
      {
        id: 4,
        content:
          'Recognized as outstanding intern out of 7 people by the R&D senior Director.',
      },
    ],
  },
];

export default workExperience;
