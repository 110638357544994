import React from 'react';
import { Table } from 'reactstrap';
import classes from './GCNwithKD.module.css';

const GCNwithKD = () => {
  return (
    <div className={classes.gcnwithkd + ' container'}>
      <div className="row">
        <h1 className="col-12">Graph Convolutional Networks (GCN) with vanilla Knowledge Distillation</h1>
        <h4 className="col-12">Pytorch implementation</h4>
      </div>
      <hr></hr>
      <div className={classes.github}>
        <i className="fa fa-github fa-2x mr-2"></i>
        <a href="https://github.com/berlincho/GCN-with-Hinton-Knowledge-Distillation">
          https://github.com/berlincho/GCN-with-Hinton-Knowledge-Distillation
        </a>
      </div>
      <div className={classes.content + ' row ml-5'}>
        <div className="col-md-2"></div>
        <div className="col-md-8 col-11">
          <p>Due to a lack of source codes of knowledge distillation relating to GCN, the vanilla implementation might be helpful for the research community.</p>
          <p>The belows are node classification results with ten differnet random initializations. It is noted that both teacher and student model have identical settings (2-layer GCN).</p>
          <Table responsive size="sm" style={{color: "white"}}>
            <thead>
              <tr>
                <th>acc(%) \ data</th>
                <th>CORA</th>
                <th>CITESEER</th>
                <th>PUBMED</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Teacher Model</th>
                <td>81.16±0.57</td>
                <td>70.74±0.68</td>
                <td>78.96±0.49</td>
              </tr>
              <tr>
                <th scope="row">Student Model</th>
                <td>81.83±1.53</td>
                <td>71.45±0.85</td>
                <td>79.48±0.41</td>
              </tr>
            </tbody>
          </Table>
          <hr/>
          <ul>
            <li>[1] Kipf, T., & Welling, M. (2017). Semi-Supervised Classification with Graph Convolutional Networks. ArXiv, abs/1609.02907.</li>
            <li>[2] Hinton, G.E., Vinyals, O., & Dean, J. (2015). Distilling the Knowledge in a Neural Network. ArXiv, abs/1503.02531.</li>
            <li>[3] Gou, J., Yu, B., Maybank, S., & Tao, D. (2021). Knowledge Distillation: A Survey. Int. J. Comput. Vis., 129, 1789-1819.</li>
          </ul>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}
export default GCNwithKD;