const ProjectData = [
  {
    id: 1,
    title: "Play Sound Effects: volume up and press key from 'a' to 'z' on PC",
    description: 'Utilized paper.js and howler.js to build interactive playground where users can play speical sound effects using PC keyboard.',
    image: "./assets/project/patatap.jpeg",
    tag: [
      'Javascript', 'React.js', 'Web Development'
    ],
    route: '/projects/patatap' ,
    github: null,
  },
  {
    id: 2,
    title: "E-commerce Ticketing System",
    description: 'Developed microservice architectured online ticketing web application using Kubernetes and NATS-streaming in Typescript.',
    image: "./assets/project/ticketing-system.jpeg",
    tag: ['Typescript', 'Microservices', 'Web Development'],
    route: null,
    github: 'https://github.com/berlincho/e-commerce-ticketing',
  }, 
  {
    id: 3,
    title: "Charmbo - Dating App",
    description: "Developed Tinder-like dating web application. Main features include match-making, chatting room, and user management.",
    image: "./assets/project/charmbo.png",
    tag: ['Express', "socket.io", "Web Development"],
    route: null,
    github: null,
    link: "https://charmbo-dating-app.herokuapp.com"
  },
  {
    id: 4,
    title: "Yichichu - Event Calendar",
    description: "Built an event calendar allowing user to manage personal event schedule. Supports KKTIX and Accupass (two of the largest ticketing websites in Taiwan).",
    image: "./assets/project/yichichu.png",
    tag: ['GraphQL', 'Web Scraper', "Web Development"],
    route: null,
    github: null,
    link: "https://www.yichichu.com"
  },
  {
    id: 5,
    title: "Relational Graph Convolutional Networks (Pytorch implementation)",
    description: 'Implemented Relational Graph Convolutional Networks that specially target for semi-supervised node classification using Pytorch.',
    image: "./assets/project/gcn.png",
    tag: ['Knowledge Graph', 'Social Networks', 'Machine Learning'],
    route: '/projects/rgcn-pytorch',
    github: 'https://github.com/berlincho/RGCN-pytorch',
  }, 
  {
    id: 6,
    title: "Graph Convolutional Networks (GCN) with vanilla Knowledge Distillation",
    description: "Implemented Teacher-Student architecture (proposed by [Hinton et al. 2015]) using Kipf's Graph Convolutional Network (GCN) as a base model.",
    image: "./assets/project/gcnwithkd.png",
    tag: ['Model Compression', 'Knowledge Distillation', 'Machine Learning'],
    route: "/projects/gcn-with-hinton-knowledge-distillation",
    github: "https://github.com/berlincho/GCN-with-Hinton-Knowledge-Distillation",
  },
  {
    id: 7,
    title: "Serial Port Terminal",
    description: 'Developed Serial Port Terminal Graphical User Interface (GUI) using Python Tkinter, including open/close device, logging, sending files/commands features.',
    image: "./assets/project/serialport.jpeg",
    tag: ['Python', 'GUI', 'Software Engineering'],
    route: '/projects/serial-port-gui',
    github: 'https://github.com/berlincho/serial-port-terminal-GUI',
  },
  {
    id: 8,
    title: "Simple URL Shortener System",
    description: "Implement basic url shortener system with Redis caching and MongoDB. (Login is Required due to security concerns. Contact Admin for details).",
    image: "./assets/project/url.png",
    tag: ['NodeJS', 'Cache', 'System Design'],
    route: "/url",
    github: null,
  }

];

export default ProjectData;
